.post-background {
 /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); 
 background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
 background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.timestamp {
  font-style: italic;
  font-size: 12px;
}

.post-header {
  height: auto;
  min-height: 100%;
}

.post-body {
  margin-top: 3vh;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: #424242;
}

/* Icon container's border */
.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--work .vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #11cdef, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.vertical-timeline-element--work .vertical-timeline-element-content {
  border-top: 3px solid #172b4d;
}

.vertical-timeline-element-date {
  color: #172b4d;
}

.vertical-timeline-element-title {
  color: #172b4d
}

.numberList {
  font-size: 40px;
}

.textList {
  margin: auto;
}